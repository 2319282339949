
import { mapState } from 'vuex'
import Loader from '@/components/Loader'

export default {
  name: 'Profile',
  components: {
    Loader,
  },
  data() {
    return {
      fields: {
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
        password2: '',
        demo_account: false,
      },
      errorToShow: null,
      payloadForProfile: null,
      userData: null,
      isNotificationSuccessVisible: false,
      notificationSuccessTimeout: null,
    }
  },
  computed: {
    ...mapState('organizations', [
      'getOrganizationsResult',
      'getOrganizationsIsLoading',
      'getOrganizationsError',
    ]),
    ...mapState('users', [
      'getCurrentProfileResult',
      'getCurrentProfileIsLoading',
      'getCurrentProfileError',

      'getUsersItemResult',
      'getUsersItemIsLoading',
      'getUsersItemError',

      'editProfilesItemResult',
      'editProfilesItemIsLoading',
      'editProfilesItemError',

      'editUsersItemResult',
      'editUsersItemIsLoading',
      'editUsersItemError',
    ]),
    isLoading() {
      return !this.userData ||
        this.editProfilesItemIsLoading ||
        this.editUsersItemIsLoading ||
        this.getCurrentProfileIsLoading ||
        this.getUsersItemIsLoading ||
        this.getOrganizationsIsLoading;
    },
    isEmailValid() {
      return this.fields.email && this.fields.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },
    error() {
      const error = this.getOrganizationsError ||
        this.getCurrentProfileError ||
        this.editUsersItemError ||
        this.editProfilesItemError;
      return error ? error.replace('password1', 'Password:').replace('password2', 'Password Repeat:') : error;
    },
    isPasswordComboValid() {
      return this.fields.password1 === this.fields.password2;
    },
    isPassword2Valid() {
      return this.fields.password1 ? this.fields.password2 : true;
    },
    isValid() {
      if (!this.isEmailValid ||
        !this.isPasswordComboValid || !this.isPassword2Valid
      ) {
        return false;
      }
      return true;
    },
    isDisabled() {
      return this.isLoading || !this.isValid;
    },
    name() {
      return [this.userData?.first_name, this.userData?.last_name].filter(x => !!x).join(' ');
    },
    title() {
      return this.name || this.fields.username;
    },
    organization() {
      if (!this.getCurrentProfileResult || !this.getOrganizationsResult) {
        return '';
      }
      return this.getOrganizationsResult.find(x => x.id === this.getCurrentProfileResult.organization)?.name || '';
    },
  },
  methods: {
    setUser(data) {
      this.userData = data;
      this.setData(data);
    },
    setData(data) {
      Object.keys(data).forEach(key => {
        if (this.fields[key] !== undefined) {
          this.fields[key] = data[key];
        }
      })
    },
    showNotification() {
      if (this.notificationSuccessTimeout !== null) {
        clearTimeout(this.notificationSuccessTimeout);
        this.notificationSuccessTimeout = null;
      }
      this.isNotificationSuccessVisible = true;
      this.notificationSuccessTimeout = setTimeout(() => {
        this.isNotificationSuccessVisible = false;
        clearTimeout(this.notificationSuccessTimeout);
        this.notificationSuccessTimeout = null;
      }, 2000);
    },
    onSubmit(e) {
      e.preventDefault();
      const payload = {
        ...this.fields,
      };
      if (!this.fields.password1) {
        delete payload.password1;
        delete payload.password2;
      }
      this.$store.dispatch('users/editUsersItem', { user: this.userData.id, ...payload, onSuccess: this.setUser });
      this.$store.dispatch('users/editProfilesItem', { profile: this.getCurrentProfileResult.id, ...payload });
    },
  },
  beforeMount() {
    if (!this.getOrganizationsResult && !this.getOrganizationsIsLoading) {
      this.$store.dispatch('organizations/getOrganizations');
    }
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        this.errorToShow = null;
      } else if (this.error) {
        this.errorToShow = this.error;
      }
    },
    getCurrentProfileResult: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.$store.dispatch('users/getUsersItem', { id: newVal.user, onSuccess: this.setUser });
          this.setData(newVal);
        }
      },
    },
    editProfilesItemIsLoading(newVal) {
      if (!newVal && !this.editUsersItemIsLoading && this.editUsersItemResult && this.editProfilesItemResult) {
        this.showNotification();
      }
    },
    editUsersItemIsLoading(newVal) {
      if (!newVal && !this.editProfilesItemIsLoading && this.editUsersItemResult && this.editProfilesItemResult) {
        this.showNotification();
      }
    }
  },
}
